import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = ' LaunchLab Sol is a results-driven agency that builds systems for success in Business Development, E-Commerce (Amazon Private Label), and Web Development. We optimize operations, launch impactful Amazon brands, and create engaging websites that convert visitors into loyal customers. Let us turn your vision into reality with strategic innovation and exceptional service.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Business Development</h3>
                                                <p>LaunchLab Sol holds expertise in building rigorous systems that streamline business operations and drive strategic growth. We design tailored solutions that empower organizations to optimize their processes, enhance efficiency, and unlock new opportunities for success.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">E-Commerce (Private Label)</h3>
                                                <p>In the realm of e-commerce, we specialize in launching and scaling Amazon Private Label brands, crafting compelling product strategies that resonate with consumers. Our data-driven approach ensures your brand stands out in a competitive marketplace, maximizing visibility and driving sales.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;