import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiArrowDown, FiBox, FiCamera, FiDollarSign, FiBarChart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FaFileWord } from "react-icons/fa";


const ServiceList = [
    {
        icon: <FiBarChart />,
        title: 'Product Research & Selection',
        description: 'Identifying profitable niches and high-demand products for your private label.'
    },
    {
        icon: <FiLayers />,
        title: 'Supplier Sourcing & Negotiation',
        description: 'Finding reliable manufacturers and negotiating favorable terms for production.'
    },
    {
        icon: <FiUsers />,
        title: 'Brand Development & Logo Design',
        description: 'Creating a unique brand identity, including logo design and brand messaging.'
    },
    {
        icon: <FiBox />,
        title: 'Packaging Design & Product Labeling',
        description: 'Designing custom packaging and labels that comply with Amazon’s guidelines and stand out to customers.'
    },
    {
        icon: <FiCast />,
        title: 'Amazon Account Setup',
        description: 'Assisting with setting up your Amazon seller account and ensuring it meets all necessary requirements.'
    },
    {
        icon: <FiMonitor />,
        title: 'Company Registration Assistance',
        description: 'Providing guidance and support in registering your company, ensuring all legal aspects are covered.'
    },
    {
        icon: <FaFileWord />,
        title: 'Keyword Research & SEO Optimization',
        description: 'Conducting in-depth keyword research to optimize product listings and improve search rankings.'
    },
    {
        icon: <FiCast />,
        title: 'Patent Research & Trademark Services',
        description: 'Conducting patent searches and helping you secure trademarks to protect your brand and products.'
    },
    {
        icon: <FiMonitor />,
        title: 'Amazon Listing Creation & Optimization',
        description: 'Writing optimized product titles, bullet points, and descriptions, along with keyword research and SEO.'
    },
    {
        icon: <FiCamera />,
        title: 'Product Photography & Enhanced Brand Content (EBC)',
        description: 'High-quality product images and A+ content to enhance your Amazon listings.'
    },
    {
        icon: <FiCast />,
        title: 'Amazon FBA (Fulfillment by Amazon) Setup',
        description: 'Assisting with FBA setup, inventory management, and shipping logistics.'
    },
    {
        icon: <FiDollarSign />,
        title: 'PPC Campaign Management',
        description: 'Managing Amazon PPC campaigns to drive traffic and increase sales.'
    },
    {
        icon: <FiMonitor />,
        title: 'Product Launch Strategy',
        description: 'Crafting a comprehensive launch plan, including promotions, giveaways, and review generation.'
    },
    {
        icon: <FiMonitor />,
        title: 'Amazon Storefront Design',
        description: 'Creating and optimizing an Amazon Storefront to enhance brand presence.'
    },
    {
        icon: <FiCast />,
        title: 'Inventory Management & Replenishment',
        description: 'Monitoring stock levels and coordinating with suppliers for timely replenishment.'
    },
    {
        icon: <FiMonitor />,
        title: 'Customer Service Management',
        description: 'Handling customer inquiries, reviews, and returns to maintain a positive seller reputation.'
    },
    {
        icon: <FiDollarSign />,
        title: 'Sales & Performance Analytics',
        description: 'Providing detailed reports and analysis to track sales performance and make data-driven decisions.'
    },
    {
        icon: <FiMonitor />,
        title: 'Amazon Compliance & Policy Management',
        description: 'Ensuring your products and practices comply with Amazon’s ever-changing policies and guidelines.'
    },
    {
        icon: <FiMonitor />,
        title: 'Global Expansion Services',
        description: 'Assisting with expanding your private label brand into Amazon’s global marketplaces.'
    },
    {
        icon: <FiCast />,
        title: 'Inventory Management & Replenishment',
        description: 'Helping you secure trademarks and enroll in Amazon’s Brand Registry for enhanced brand protection.'
    },
    {
        icon: <FiMonitor />,
        title: 'Product Marketing Testing services (PMT)',
        description: 'Implementing strategies to encourage positive reviews and managing feedback.'
    },
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'E-Commerce'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Amazon Private Label (A to Z) </h2>
                                    <p>Our comprehensive Amazon Private Label services cover every step of the journey—from product ideation to global expansion—ensuring your brand thrives in the competitive e-commerce landscape. <br /> </p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Strategy</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Creative Agency</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Development</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;