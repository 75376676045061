import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                    <a href="https://ui-morph-git-hassan-branch-itconsultant.vercel.app/" target={"_blank"}> 
                    <img src="/assets/images/brand/brand-01.png" alt="Logo Images"/>
                    </a>
                    </li>
                   
                    <li>
                    <a href="https://myapp-2.vercel.app/" target={"_blank"}> 
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://quickround.tech/login" target={"_blank"}> 
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                        </a>
                    </li> 
                    <li>
                    <a href="https://lap-3mnv.vercel.app/" target={"_blank"}> 
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://balayara.ae/" target={"_blank"}> 
                        <img src="/assets/images/brand/brand-05.png" alt="Logo Images"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://ectobytes.com/" target={"_blank"}> 
                        <img src="/assets/images/brand/brand-06.png" alt="Logo Images"/>
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;