import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent logoresize" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">WEBSITE DEVELOPMENT</h2>
                                    <p>Expert web development solutions that drive business growth.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>At TRO tech, we offer a wide range of web development services designed to meet the unique needs of businesses across various industries. Whether you're a startup or an established enterprise, we have the expertise and experience to create scalable, high-performance web solutions that drive your business forward.</p>
                                                    <p>Our team of skilled developers uses the latest technologies and development methodologies to ensure that your web application is tailored to your specific requirements, delivering seamless user experiences and exceptional performance. From simple websites to complex web applications, we work closely with our clients to understand their needs and deliver custom solutions that exceed their expectations.</p>
                                                    <h4 className="title">Comprehensive Web Development Services</h4>
                                                    <ul className="liststyle">
                                                        <li>Custom web development solutions tailored to your business needs</li>
                                                        <li>Modern UI/UX design for optimal user experiences</li>
                                                        <li>Integration with third-party APIs and systems</li>
                                                        <li>Cross-platform compatibility and responsive design</li>
                                                        <li>Scalable, high-performance applications built with the latest technologies</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>At TRO tech, we are committed to providing end-to-end web development services, from initial consultation and concept design to development, testing, and deployment. Our team's goal is to help businesses like yours achieve their digital objectives and improve their online presence through innovative and reliable web solutions.</p>
                                                    {/* <p>hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.</p> */}
                                                    <h4 className="title">Expert Web Solutions for Your Business Needs</h4>
                                                    <ul className="liststyle">
                                                        <li>In-depth consultation and needs analysis to understand your business objectives</li>
                                                        <li>Agile development methodologies for flexibility and adaptability</li>
                                                        <li>Expertise across a wide range of web development technologies and frameworks</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;